import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import IndustriesList from "./industries-list"

const IndustriesTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter

  return (
    <Layout>
      <section className="IndustriesTemplate">
        <Banner data={banner} />
        <IndustriesList />
      </section>
    </Layout>
  )
}

export const IndustriesTemplateQueryByID = graphql`
  query IndustriesTemplateQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default IndustriesTemplate
